<template>
  <v-container class="text-center" fill-height style="height: calc(100vh - 58px);">
    <v-row align="center">
      <v-col>
        <h1 class="display-2 primary--text">404</h1>

        <p>{{ $vuetify.lang.t('$vuetify.errors.page_not_found') }}</p>

        <v-btn
          to="/"
          color="primary"
          outlined
        >{{ $vuetify.lang.t('$vuetify.buttons.return_to_main') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FourOhFour",
  props: {
    lang: {
      type: String,
      default: "ka"
    }
  },
  created() {
    this.$ssrContext && this.$ssrContext.res.status(404);
  }
};
</script>